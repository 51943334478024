import React from "react";
import "./PrivacyPolicy.css";
import TextSection from "../components/TextSection";
import UpperParagraph from "../components/UpperParagraph";
import InfoTemplate from "../components/InfoTemplate";
import { connect } from "react-redux";
import { getStaticPrivacyPolicyCopies } from "../../../utils/constants/copies/copies.helper";

/**
 * @param {String} children
 * @returns {Element}
 */
const Bolder = ({ children }) => {
  return <span className="ff-popp-med">{children}</span>;
};

/**
 * @param {String} children
 * @returns {Element}
 */
const Paragraph = ({ children }) => {
  return <div style={{ marginBottom: "1rem" }}>{children}</div>;
};

/**
 * @param {Elements} children
 * @returns {Element}
 */
const List = ({ children }) => {
  return <ol style={{ paddingLeft: "1.5rem" }}>{children}</ol>;
};

/**
 * Functional Component: privacy Policy
 * Renders the privacy policy page
 * @param {String} language
 * @returns {Component}
 */
const PrivacyPolicy = ({ language }) => {
  //contains all the copies for the privacy policy component
  const SELF_COPIES = getStaticPrivacyPolicyCopies({ language });

  return (
    <InfoTemplate id="new-privacy-policy" title={SELF_COPIES.mainTitle}>
      <UpperParagraph>
        {SELF_COPIES.PARAGRAPH_1_SENTENCE_1.first_part}
        <Bolder>PUR'PLE</Bolder>
        {SELF_COPIES.PARAGRAPH_1_SENTENCE_1.second_part}
        <Bolder>{SELF_COPIES.PARAGRAPH_1_SENTENCE_1.website}</Bolder>
        {SELF_COPIES.PARAGRAPH_1_SENTENCE_1.third_part}
        <Bolder>{SELF_COPIES.PARAGRAPH_1_SENTENCE_1.service}</Bolder>.
      </UpperParagraph>

      <UpperParagraph>{SELF_COPIES.PARAGRAPH_1_SENTENCE_2}</UpperParagraph>

      <UpperParagraph>{SELF_COPIES.PARAGRAPH_1_SENTENCE_3}</UpperParagraph>

      <UpperParagraph>{SELF_COPIES.PARAGRAPH_1_SENTENCE_4}</UpperParagraph>

      <TextSection title={SELF_COPIES.PERSONAL_DATA_RESPONSIBILITY_TITLE}>
        {SELF_COPIES.PERSONAL_DATA_RESPONSIBILITY_BODY.first_part}
        <Bolder>PUR’PLE</Bolder>
        {SELF_COPIES.PERSONAL_DATA_RESPONSIBILITY_BODY.second_part}
        <Bolder>
          {SELF_COPIES.PERSONAL_DATA_RESPONSIBILITY_BODY.controller}
        </Bolder>
        ").
      </TextSection>

      <TextSection title={SELF_COPIES.PERSONAL_DATA_TREATMENT_TITLE}>
        <div style={{ marginBottom: "1rem" }}>
          {SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_SENTENCE_1.first_part}
          <Bolder>
            {SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_SENTENCE_1.purposes}
          </Bolder>
        </div>

        <List>
          <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_1}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_2}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_3}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_4}</li>
          <li>
            {SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_5}
            <ol type="a">
              <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_6}</li>
              <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_7}</li>
              <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_8}</li>
              <li>{SELF_COPIES.PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_9}</li>
            </ol>
          </li>
        </List>
      </TextSection>

      <TextSection title={SELF_COPIES.PERSONAL_DATA_TITLE}>
        <Paragraph>{SELF_COPIES.PERSONAL_DATA_SENTENCE_1}</Paragraph>

        <List>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_2}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_3}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_4}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_5}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_6}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_7}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_8}</li>
          <li>{SELF_COPIES.PERSONAL_DATA_SENTENCE_9}</li>
        </List>

        <Paragraph>{SELF_COPIES.PERSONAL_DATA_SENTENCE_10}</Paragraph>

        <Paragraph>{SELF_COPIES.PERSONAL_DATA_SENTENCE_11}</Paragraph>
      </TextSection>

      <TextSection title={SELF_COPIES.RETENTION_DATA_TITLE}>
        <Paragraph style={{ marginBottom: "1rem" }}>
          {SELF_COPIES.RETENTION_DATA_SENTENCE_1}
        </Paragraph>

        <table
          className="retention-of-personal-data-table"
          style={{ marginBottom: "1rem" }}
        >
          <thead>
            <th>{SELF_COPIES.RETENTION_DATA_TABLE_HEADERS.first}</th>
            <th>{SELF_COPIES.RETENTION_DATA_TABLE_HEADERS.second}</th>
            <th>{SELF_COPIES.RETENTION_DATA_TABLE_HEADERS.third}</th>
          </thead>

          <tbody>
            <tr>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_1.one}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_1.two}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_1.three}</td>
            </tr>
            <tr>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_2.one}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_2.two}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_2.three}</td>
            </tr>
            <tr>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_3.one}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_3.two}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_3.three}</td>
            </tr>
            <tr>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_4.one}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_4.two}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_4.three}</td>
            </tr>
            <tr>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_5.one}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_5.two}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_5.three}</td>
            </tr>
            <tr>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_6.one}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_6.two}</td>
              <td>{SELF_COPIES.RETENTION_DATA_TABLE_ROW_6.three}</td>
            </tr>
          </tbody>
        </table>

        <Paragraph>{SELF_COPIES.RETENTION_DATA_SENTENCE_2}</Paragraph>
      </TextSection>

      <TextSection title={SELF_COPIES.RIGHTS_OF_DATA_TITLE}>
        <Paragraph>{SELF_COPIES.RIGHTS_OF_DATA_SENTENCE_1}</Paragraph>
        <Paragraph>{SELF_COPIES.RIGHTS_OF_DATA_SENTENCE_1_5}</Paragraph>
        <Paragraph>{SELF_COPIES.RIGHTS_OF_DATA_SENTENCE_2}</Paragraph>
        <Paragraph>{SELF_COPIES.RIGHTS_OF_DATA_SENTENCE_3}</Paragraph>
        <Paragraph>
          {SELF_COPIES.RIGHTS_OF_DATA_SENTENCE_4}{" "}
          {SELF_COPIES.RIGHTS_OF_DATA_SENTENCE_5}
        </Paragraph>
      </TextSection>

      <TextSection title={SELF_COPIES.SECURITY_TITLE}>
        <Paragraph>{SELF_COPIES.SECURITY_TITLE_SENTENCE_1}</Paragraph>

        <List>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_2}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_3}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_4}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_5}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_6}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_7}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_8}</li>
          <li>{SELF_COPIES.SECURITY_TITLE_SENTENCE_9}</li>
        </List>

        <Paragraph>{SELF_COPIES.SECURITY_TITLE_SENTENCE_10}</Paragraph>

        <Paragraph>{SELF_COPIES.SECURITY_TITLE_SENTENCE_11}</Paragraph>

        <Paragraph>{SELF_COPIES.SECURITY_TITLE_SENTENCE_12}</Paragraph>
      </TextSection>

      <TextSection title={SELF_COPIES.COMUNICATION_OF_PERSONAL_DATA_TITLE}>
        <Paragraph>
          {SELF_COPIES.COMUNICATION_OF_PERSONAL_DATA_SENTENCE_1}
        </Paragraph>
        <Paragraph>
          {SELF_COPIES.COMUNICATION_OF_PERSONAL_DATA_SENTENCE_2}
        </Paragraph>
        <List>
          <li>{SELF_COPIES.COMUNICATION_OF_PERSONAL_DATA_SENTENCE_3}</li>
          <li>{SELF_COPIES.COMUNICATION_OF_PERSONAL_DATA_SENTENCE_4}</li>
          <li>{SELF_COPIES.COMUNICATION_OF_PERSONAL_DATA_SENTENCE_5}</li>
        </List>
      </TextSection>

      <TextSection title={SELF_COPIES.PRIVACY_POLICY_CHANGES_TITLE}>
        {SELF_COPIES.PRIVACY_POLICY_CHANGES_SENTENCE_1}
      </TextSection>
    </InfoTemplate>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(PrivacyPolicy);
